import React from "react";
// import pug from "../assets/placeholders/2.jpg"
import ben from "../assets/ben.jpg";
import { ChevronDoubleDownOutline } from "heroicons-react";
import { faLinkedinIn } from "@fortawesome/free-brands-svg-icons";
import { faGithub } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Link } from "react-scroll";

const FaceBubble = () => {
  return (
    <div className="container relative mx-auto">
      <div className="flex flex-wrap items-center">
        <div className="grid md:grid-cols-2">
          <div className="p-16">
            <img
              src={ben}
              alt="..."
              className="h-auto max-w-full align-middle border-none rounded-full xl:w-7/12 lg:w-8/12"
            />
          </div>
          <div className="w-full mt-auto mb-auto ml-auto mr-auto text-center">
            <div className="pb-4 text-3xl font-semibold sm:text-3xl xl:text-6xl md:text-5xl">
              Hi, I'm {/* <Link to="/about"> */}
              <Link
                to="work"
                smooth={true}
                duration={700}
                className="underline cursor-pointer title-wobble"
              >
                Benjamin Kilimnik
              </Link>
              {/* </Link> */}
            </div>
            <p className="mt-4 text-xl text-gray-600">
              <p className="py-4">
                Engineering performant and privacy-conscious systems.
                {/* Product-focused software engineer interested in privacy-conscious and high performance systems. */}
                {/* Experience in Privacy-conscious Systems, eBPF, Distributed Systems, Databases, and ML. */}

                {/* TODO: add blurb to add personality. If they wanted to know my projects they'd just look at my resume.
                This adds color. */}

                {/* Working on a
                <a
                  href="https://kilimnik.org/ppa"
                  className="text-blue-800 hover:text-blue-500"
                  rel="noreferrer"
                >
                  {" "}privacy-preserving ad ecosystem,{" "}
                </a>
                <a
                  href="https://kilimnik.org/systems"
                  className="text-blue-800 hover:text-blue-500"
                  rel="noreferrer"
                >
                  {" "}a GDPR-compliant database,{" "}
                </a>
                an
                <a
                  href="https://github.com/pixie-io/pixie"
                  className="text-blue-800 hover:text-blue-500"
                  target="_blank"
                  rel="noreferrer"
                >
                  {" "}eBPF-based observability tool,{" "}
                </a>
                a
                <a
                  href="https://kilimnik.org/sbudnic"
                  className="text-blue-800 hover:text-blue-500"
                  rel="noreferrer"
                >
                  {" "}
                  3U cube satellite,{" "}
                </a>
                and an
                <a
                  href="https://kilimnik.org/pii"
                  className="text-blue-800 hover:text-blue-500"
                  rel="noreferrer"
                >
                  {" "}
                  ML model to redact PII.{" "}
                </a> */}

              </p>
            </p>
            <div className="flex flex-col items-center ">
              <div className="flex flex-row items-center space-x-8">
                <a
                  href="https://linkedin.com/in/benkilimnik"
                  target="_blank"
                  rel="noreferrer"
                  className="transition duration-500 ease-out transform opacity-50 hover:scale-110 hover:opacity-100"
                >
                  <FontAwesomeIcon icon={faLinkedinIn} size="2x" />
                </a>
                <a
                  href="https://github.com/benkilimnik"
                  target="_blank"
                  rel="noreferrer"
                  className="transition duration-500 ease-out transform opacity-50 hover:scale-110 hover:opacity-100 "
                >
                  <FontAwesomeIcon icon={faGithub} size="2x" />
                </a>
              </div>
            </div>
            <div className="flex flex-col items-center py-6">
              <Link
                to="work"
                smooth={true}
                duration={700}
                className="cursor-pointer hover:text-blue-500"
              >
                <ChevronDoubleDownOutline />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FaceBubble;
