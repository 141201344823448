import React, { Component } from "react";
import { faLinkedinIn } from "@fortawesome/free-brands-svg-icons";
// import { faGithub } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import * as emailjs from "emailjs-com";

class Contact extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  state = {
    name: "",
    email: "",
    subject: "",
    message: "",
    sentMessage: false,
    showBanner: false,
    isLoading: false,
  };



  handleSubmit(e) {
    e.preventDefault();
    this.setState({ isLoading: true });
    const { name, email, subject, message } = this.state;
    let templateParams = {
      from_name: name,
      from_email: email,
      to_name: "Benji",
      subject: subject,
      message_html: message,
    };
    console.log(templateParams);
    // add trigger to show a notification on screen that message was successfully sent
    emailjs.send(
      "service_z1aknlv",
      "template_z2qt74o",
      templateParams,
      "user_vHhBlh76iJTulcQ9d2Bfg"
    ).then(response => {
      console.log('SUCCESS!', response.status, response.text);
      this.setState({ sentMessage: true, isLoading: false, showBanner: true }); // Update state to show notification
      this.resetForm();
    }, error => {
      this.setState({ isLoading: false });
      console.log('FAILED...', error);
    });
    this.resetForm();

    setTimeout(() => {
      this.setState({ sentMessage: false });
    }, 3000); // Reverting back to initial state after 3 seconds
  }
  resetForm() {
    this.setState({
      name: "",
      email: "",
      subject: "",
      message: "",
    });
  }
  handleChange = (param, e) => {
    this.setState({ [param]: e.target.value });
  };

  render() {
    return (
      <div className="container flex flex-col items-center justify-center max-w-2xl py-6 mx-auto leading-7 text-left">
        <h1 className="py-16 text-4xl">Let's get in touch.</h1>

        <div className="flex flex-row items-center space-x-8">
          <a
            href="https://linkedin.com/in/benkilimnik"
            target="_blank"
            rel="noreferrer"
            className="transition duration-500 ease-out transform opacity-50 hover:scale-110 hover:opacity-100"
          >
            <FontAwesomeIcon icon={faLinkedinIn} size="2x" />
          </a>
          {/* <a
            href="https://github.com/benkilimnik"
            target="_blank"
            rel="noreferrer"
            className="transition duration-500 ease-out transform opacity-50 hover:scale-110 hover:opacity-100 "
          >
            <FontAwesomeIcon icon={faGithub} size="2x" />
          </a> */}
        </div>
        {/* <div className="container flex flex-col items-center justify-center max-w-2xl px-4 py-6 mx-auto leading-7 text-left">
          <p className="py-4">
            Need something built, a design consultation, or just want to chat?
            Fill out the form below!
          </p>
        </div> */}
        {/* <div className="form-container ">
          <div className="px-4 py-10 mb-0 rounded-t">
            <div className="flex-auto px-4 py-2 lg:px-8">
              <form onSubmit={this.handleSubmit.bind(this)}>
                <div className="relative w-full mb-6" data-children-count="1">
                  <label className="block mb-2 text-xs font-bold text-gray-700 uppercase">
                    Name
                  </label>
                  <input
                    type="Name"
                    value={this.state.name}
                    onChange={this.handleChange.bind(this, "name")}
                    name="name"
                    className="w-full px-3 py-3 text-sm text-gray-700 placeholder-gray-400 bg-white rounded shadow focus:outline-none focus:shadow-outline"
                    placeholder="Name"
                    style={{ transition: "all 0.15s ease 0s;" }}
                  />{" "}
                </div>

                <div className="relative w-full mb-6" data-children-count="1">
                  <label className="block mb-2 text-xs font-bold text-gray-700 uppercase">
                    Email
                  </label>
                  <input
                    type="email"
                    onChange={this.handleChange.bind(this, "email")}
                    value={this.state.email}
                    name="email"
                    className="w-full px-3 py-3 text-sm text-gray-700 placeholder-gray-400 bg-white rounded shadow focus:outline-none focus:shadow-outline"
                    placeholder="Email"
                    style={{ transition: "all 0.15s ease 0s;" }}
                  />
                </div>

                <div className="relative w-full mb-6" data-children-count="1">
                  <label className="block mb-2 text-xs font-bold text-gray-700 uppercase">
                    Subject
                  </label>
                  <input
                    type="text"
                    onChange={this.handleChange.bind(this, "subject")}
                    value={this.state.subject}
                    name="subject"
                    className="w-full px-3 py-3 text-sm text-gray-700 placeholder-gray-400 bg-white rounded shadow focus:outline-none focus:shadow-outline"
                    placeholder="Subject"
                    style={{ transition: "all 0.15s ease 0s;" }}
                  />
                </div>

                <div className="relative w-full mb-6" data-children-count="1">
                  <label className="block mb-2 text-xs font-bold text-gray-700 uppercase">
                    Message
                  </label>
                  <textarea
                    type="textarea"
                    onChange={this.handleChange.bind(this, "message")}
                    value={this.state.message}
                    name="message"
                    className="w-full px-3 py-3 text-sm text-gray-700 placeholder-gray-400 bg-white rounded shadow resize-y focus:outline-none focus:shadow-outline"
                    placeholder="Message"
                    style={{ transition: "all 0.15s ease 0s;" }}
                  />
                </div>

                <div className="mt-6 text-center">
                  <button
                    disabled={this.state.isLoading || this.state.sentMessage}
                    type="submit"
                    className={`w-full px-6 py-3 mb-1 mr-1 text-sm font-bold uppercase rounded shadow outline-none active:bg-blue-400 hover:shadow-lg focus:outline-none 
              ${this.state.isLoading ? 'bg-blue-300 text-white' : 'bg-blue-600 hover:bg-blue-500 text-white'}
              transition ease-in-out duration-300 transform motion-reduce:transition-none motion-reduce:transform-none`}
                  >
                    {this.state.isLoading ? (
                      <>
                        <svg aria-hidden="true" role="status" className="inline w-4 h-4 mr-2 text-white animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"></path>
                          <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="#1C64F2"></path>
                        </svg>
                        Loading...
                      </>
                    ) : this.state.sentMessage ? (
                      <>
                        <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="24" height="24" viewBox="0 0 64 64" className="inline w-4 h-4 mb-1 mr-2"
                          style={{ fill: "#FFFFFF" }}>
                          <path d="M27 55L6 33 9 29 26 41 55 12 59 16z"></path>
                        </svg>
                        Sent!
                      </>
                    ) : (
                      "Leave a Message"
                    )}
                  </button>
                </div>

              </form>
            </div>
          </div>
          {this.state.showBanner && (
            <div
              id="toast-success"
              className="fixed flex items-center w-full max-w-sm p-4 space-x-4 text-gray-500 bg-white rounded-lg shadow top-20 right-5 dark:text-gray-400 dark:bg-gray-800"
              role="alert"
              onClick={() => this.setState({ showBanner: false })}
              style={{ cursor: 'pointer' }}
            >
              <svg
                className="w-6 h-6 text-blue-600 rotate-45 dark:text-blue-500"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 18 20"
                fill="none"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m9 17 8 2L9 1 1 19l8-2Zm0 0V9"
                ></path>
              </svg>
              <div className="flex-grow pl-4 text-base font-normal border-l border-gray-200 dark:border-gray-700">
                Message sent successfully.
              </div>
              <div className="flex-shrink-0 pl-4">
                <svg
                  onClick={(e) => { e.stopPropagation(); this.setState({ showBanner: false }); }}
                  className="w-6 h-6 text-gray-700 cursor-pointer dark:text-gray-300"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <line x1="18" y1="6" x2="6" y2="18"></line>
                  <line x1="6" y1="6" x2="18" y2="18"></line>
                </svg>
              </div>
            </div>
          )}
        </div> */}
        {/* <div className="relative w-full pt-8 mb-3" data-children-count="1">
          <label className="block mb-2 text-xs font-bold text-gray-700 uppercase">
            This is a Field
          </label>
          <input
            type="email"
            className="w-full px-3 py-3 text-sm text-gray-700 placeholder-gray-400 bg-white rounded shadow focus:outline-none focus:shadow-outline"
            placeholder="Enter Something Here"
            style={{ transition: "all 0.15s ease 0s;" }}
          />
        </div> */}
      </div>
    );
  }
}

export default Contact;
