import React, { Component } from "react";
import { ebpf, ebpf2 } from "../images";
import Footer from "../Footer";
import SideToc from "../SideToc";
import * as Icons from "heroicons-react";

class EBPF extends Component {
  state = {
    showToc: true,
    showQuickLinks: false,
  };
  toggleToc = (e) => {
    this.setState({ showToc: !this.state.showToc });
  };
  toggleQuickLinks = () => {
    this.setState({ showQuickLinks: !this.state.showQuickLinks });
  };
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    const TocItems = [
      {
        header: "Introduction to eBPF",
        id: "ebpf-intro",
        icon: () => <Icons.ChipOutline size={22} />,
      },
      {
        header: "Pixie and eBPF",
        id: "pixie-ebpf",
        icon: () => <Icons.ServerOutline />,
      },
      {
        header: "Contributions",
        id: "contributions",
        icon: () => <Icons.HandOutline size={22} />,
      },
    ];

    return (
      <>
        <div className="grid grid-cols-3">
          <SideToc
            TocItems={TocItems}
            toggleToc={this.toggleToc}
            showToc={this.state.showToc}
            className={this.state.showToc ? "md:col-span-1" : "hidden"}
          />
          <div
            className={
              this.state.showToc
                ? "col-span-full md:col-start-2 md:col-end-4"
                : "col-span-full"
            }
          >
            <div className="container flex flex-col items-center justify-center max-w-2xl px-4 py-6 mx-auto leading-7 text-left">
              <h1 className="self-start py-6 text-4xl">
                eBPF: Next-Gen Observability
              </h1>

              {/* Collapsible Quick Links Box */}
              <div className="self-start py-2">
                <button className="w-full text-left" onClick={this.toggleQuickLinks}>
                  <div className="flex items-center justify-between p-2 mb-2 text-blue-700 bg-blue-100 border-l-4 border-blue-500">
                    <p className="font-semibold">Quick Links</p>
                    <Icons.ChevronDownOutline size={24} className={`${this.state.showQuickLinks ? 'transform rotate-180' : ''}`} />
                  </div>
                </button>
                <div className={`pl-2 ${this.state.showQuickLinks ? 'block' : 'hidden'}`} style={{ minHeight: '132px' }}>
                  <a
                    href="https://ebpf.io/"
                    target="_blank"
                    rel="noreferrer"
                    className="block py-2 text-blue-800 hover:text-blue-500"
                  >
                    eBPF on GitHub
                  </a>
                  <a
                    href="https://px.dev/"
                    target="_blank"
                    rel="noreferrer"
                    className="block py-2 text-blue-800 hover:text-blue-500"
                  >
                    Pixie: Observability with eBPF
                  </a>
                  <a
                    href="https://www.cncf.io/projects/pixie/"
                    target="_blank"
                    rel="noreferrer"
                    className="block py-2 text-blue-800 hover:text-blue-500"
                  >
                    CNCF Project Pixie
                  </a>
                  <a
                   href="https://github.com/pixie-io/pixie/commits/main/?author=benkilimnik"
                   target="_blank"
                   rel="noreferrer"
                   className="block py-2 text-blue-800 hover:text-blue-500"
                   >
                    My Pixie Commit
                   </a>
                </div>
              </div>

              <p className="py-4">
                eBPF (extended Berkeley Packet Filter) is transforming cloud-native observability by enabling in-kernel instrumentation. Without modifying your application code, you can leverage eBPF to monitor, trace, and profile applications at the kernel level, all with minimal overhead.
              </p>

              <h2 id="ebpf-intro" className="self-start py-4 text-3xl">
                What is eBPF?
              </h2>
              <p className="py-4">
                eBPF allows developers to run sandboxed programs in the Linux kernel without changing kernel source code or loading kernel modules. It's widely used for networking, performance profiling, and security. In observability, eBPF allows you to capture real-time metrics and logs directly from the kernel space, making it a powerful tool for understanding distributed system behavior.
              </p>

              <h2 id="pixie-ebpf" className="self-start py-4 text-3xl">
                Pixie and eBPF for Kubernetes Observability
              </h2>
              <p className="py-4">
                Pixie, a CNCF project, uses eBPF to autoinstrument Kubernetes clusters, allowing engineers to gather data on applications running in distributed environments without needing to inject sidecars or modify code. This helps simplify debugging and performance tuning.
              </p>
              <p className="py-4">
                By using eBPF, Pixie can trace system calls, network packets, and container activity at a granular level. Pixie's integration with eBPF allows you to automatically collect full-fidelity application and network data with zero instrumentation required from the user.
              </p>

              <h2 id="contributions" className="self-start py-4 text-3xl">
                My Contributions to Pixie
              </h2>
              <p className="py-4">
                I have actively contributed to Pixie's open-source repository, focusing on performance optimizations and improving the efficiency of eBPF-based data collection. One notable contribution was addressing limitations in syscall tracing by dynamically adjusting loop limits for newer kernels, ensuring more complete syscall capture.
              </p>

              <div className="my-4">
                <img src={ebpf} alt="eBPF and Kubernetes" />
                <div className="m-4">
                  <span className="block text-lg text-center truncate">
                    eBPF in action
                  </span>
                </div>
              </div>

              <p className="py-4">
                Additionally, I helped develop an experimental lazy parsing mechanism for Pixie's HTTP protocol parser to better handle partial frames, improving the observability of distributed applications in real-time.
                For more information, see this {" "}
                <a
                  href="https://github.com/pixie-io/pixie/issues/1755"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-800 hover:text-blue-500"
                >
                  github issue.
                </a>
              </p>

              <div className="my-4">
                <img src={ebpf2} className="" alt="Pixie" />
                <div className="m-4">
                  <span className="block text-lg text-center truncate">
                    Investigating Lazy Parsing in eBPF
                  </span>
                </div>
              </div>
            </div>

            <Footer
              showNext={true}
              prevItem={"./ppa"}
              nextItem={"./pii"}
            />
          </div>
        </div >
      </>
    );
  }
}

export default EBPF;